import React from 'react';
import ReactDOM from 'react-dom/client';
import { ParallaxProvider } from 'react-scroll-parallax';
import './index.css';

import reportWebVitals from './reportWebVitals';
import Router from './Router';
import { CssVarsProvider } from '@mui/joy/styles';
import Theme from './Theme';
import { LanguageProvider} from './LanguageSelector';
import posthog from 'posthog-js'

posthog.init('phc_ofRzVXIIU4itYSLZsH2v6c8zn70gBUg8dkCDVpJMUwo',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

// Ensure flags are loaded before usage.
// You'll only need to call this on the code for when the first time a user visits.
posthog.onFeatureFlags(function() {
  // feature flags should be available at this point
  if (posthog.isFeatureEnabled('my-flag') ) {
      // do something
  }
})

// Otherwise, you can just do:
if (posthog.isFeatureEnabled('my-flag') ) {
  // do something
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={Theme}>
      <LanguageProvider>
      <ParallaxProvider>
        <Router />
      </ParallaxProvider>
      </LanguageProvider>
    </CssVarsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
