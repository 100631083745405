import React, { useState } from 'react';

// import { MasonryPhotoAlbum } from "react-photo-album";
// import "react-photo-album/masonry.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { Parallax } from 'react-scroll-parallax';

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function assetLink(asset) {
  return  `${process.env.PUBLIC_URL}/photos/${asset}`//`https://assets.react-photo-album.com/_next/image?url=${encodeURIComponent(`/_next/static/media/${asset}`)}&w=${width}&q=75`;
}

const photos = [
  {
    asset: "1.jpeg",
    width: 1080,
    height: 780,
    alt: "Hiking boots",
  },
  {
    asset: "2.jpeg",
    width: 1080,
    height: 1620,
    alt: "Purple petaled flowers near a mountain",
  },
  {
    asset: "3.jpeg",
    width: 1080,
    height: 720,
    alt: "A person pointing at a beige map",
  },
  {
    asset: "4.jpeg",
    width: 1080,
    height: 720,
    alt: "Two hikers walking toward a snow-covered mountain",
  },
  {
    asset: "5.jpeg",
    width: 1080,
    height: 1620,
    alt: "A silver and black coffee mug on a brown wooden table",
  },
  {
    asset: "6.jpeg",
    width: 1080,
    height: 607,
    alt: "A worm's eye view of trees at night",
  },
  {
    asset: "7.jpeg",
    width: 1080,
    height: 608,
    alt: "A pine tree forest near a mountain at sunset",
  },
  {
    asset: "8.jpeg",
    width: 1080,
    height: 720,
    alt: "Silhouette photo of three hikers near tall trees",
  },
  {
    asset: "9.jpeg",
    width: 1080,
    height: 1549,
    alt: "A person sitting near a bonfire surrounded by trees",
  },
  {
    asset: "10.jpeg",
    width: 1080,
    height: 720,
    alt: "Green moss on gray rocks in a river",
  },
  {
    asset: "11.jpeg",
    width: 1080,
    height: 694,
    alt: "Landscape photography of mountains",
  },
  {
    asset: "12.jpeg",
    width: 1080,
    height: 1620,
    alt: "A pathway between green trees during daytime",
  },
  {
    asset: "13.jpeg",
    width: 1080,
    height: 720,
    alt: "A man wearing a black jacket and backpack standing on a grass field during sunset",
  },
  {
    asset: "14.jpeg",
    width: 1080,
    height: 1440,
    alt: "Green pine trees under white clouds during the daytime",
  },
  {
    asset: "15.jpeg",
    width: 1080,
    height: 1620,
    alt: "A hiker sitting near the cliff",
  },
  {
    asset: "16.jpeg",
    width: 1080,
    height: 810,
    alt: "A tall mountain with a waterfall running down its side",
  },
  {
    asset: "17.jpeg",
    width: 1080,
    height: 595,
    alt: "Blue mountains",
  },
  {
    asset: "18.jpeg",
    width: 1080,
    height: 160,
    alt: "Green trees on a brown mountain under a blue sky during the daytime",
  },
  {
    asset: "19.jpeg",
    width: 1080,
    height: 810,
    alt: "A red flower on a green grass field during the daytime",
  },
  {
    asset: "20.jpeg",
    width: 1080,
    height: 720,
    alt: "A sign warning people not to disturb nature",
  },
  {
    asset: "21.jpeg",
    width: 1080,
    height: 720,
    alt: "A sign warning people not to disturb nature",
  },
  {
    asset: "22.jpeg",
    width: 1080,
    height: 720,
    alt: "A sign warning people not to disturb nature",
  },
  {
    asset: "23.jpeg",
    width: 1080,
    height: 720,
    alt: "A sign warning people not to disturb nature",
  },
  {
    asset: "24.jpeg",
    width: 1080,
    height: 720,
    alt: "A sign warning people not to disturb nature",
  },
  {
    asset: "25.jpeg",
    width: 1080,
    height: 720,
    alt: "A sign warning people not to disturb nature",
  },
].map(
  ({ asset, alt, width, height }) =>
    ({
      src: assetLink(asset),
      alt,
      width,
      srcSet: breakpoints.map((breakpoint) => ({
        src: assetLink(asset, breakpoint),
        width: breakpoint,
        height: Math.round((height / width) * breakpoint),
      })),
    })
);

export default function PhotoGallery() {
  const [index, setIndex] = useState(-1);
  return <>
  {/* <MasonryPhotoAlbum photos={photos}  onClick={({ index }) => setIndex(index)}/> */}
  <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
    <div style={{display: "flex", flexDirection: "column", width: "50%", margin: "0.5em"}}>
      <Parallax translateY={[0, -5]}>
        {photos.filter((_, i) => i %2 == 0).map((photo, i) => (
          <img
            key={i}
            src={photo.src}
            onClick={() => setIndex(i*2)}
            width="100%"
            height="auto"
            style={{marginTop: "1em"}}
            />))}
      </Parallax>
    </div>
    <div style={{display: "flex", flexDirection: "column", width: "50%", margin: "1em"}}>
      <Parallax translateY={[0, 5]}>
        {photos.filter((_, i) => i %2 == 1).map((photo, i) => (
          <img
            key={i}
            src={photo.src}
            onClick={() => setIndex(i*2 + 1)}
            width="100%"
            height="auto"
            style={{marginTop: "1em"}}
            />))}
      </Parallax>
    </div>
  </div>
  <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
  </>
}