import React from 'react';
import { LanguageSelector } from './LanguageSelector';
import IconButton from '@mui/joy/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';

const TopMenu = ({onLogout, children}) => {
    return (
        <div style={{ position: 'fixed', top: '0', right: '0', display: "flex", zIndex: "100", width: "100%", justifyContent: "space-between"}}>
            <div>
                {children}
            </div>
            <div>
            <LanguageSelector />
            {onLogout && <IconButton color="primary" onClick={() => onLogout()} style={{Margin: "5px"}}>
                <LogoutIcon />
            </IconButton>}
            </div>
        </div>
    );
};

export default TopMenu;