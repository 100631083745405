import React, { useEffect, useState } from 'react';
import Login from './Login';
import Main from './Main';
import SaveTheDate from './SaveTheDate';
import Stats from './Stats';
import { parseJwt } from './parseJwt';
import posthog from 'posthog-js'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const Router = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));
    const [token, setToke] = useState(localStorage.getItem("token"));
    const isNewHomeEnabled = useFeatureFlagEnabled("main-page");

    useEffect(() => {
        if(!token) return;

        posthog.identify(parsedToke["cognito:username"]);
        console.log("Identified user", parsedToke["cognito:username"]);
    }, [token]);

    const handleLogin = (authData) => {
        setIsLoggedIn(true);
        localStorage.setItem("token", authData.data);
        setToke(authData.data);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem("token");
        setToke(null);
        posthog.identify('logedout');
    };
    if(!isLoggedIn) return <Login onLogin={handleLogin} />;

    const parsedToke = parseJwt(token);
    const isAdmin = isLoggedIn && token && parsedToke["cognito:groups"]?.includes("app_admins");
    if(isAdmin) return <Stats token={token} onLogout={handleLogout}/>;

    if(isNewHomeEnabled === null) {
        return "Loading...";
    }

    if(isNewHomeEnabled) return <Main token={token} onLogout={handleLogout} />;

    return <SaveTheDate token={token} onLogout={handleLogout}/>;
};

export default Router;