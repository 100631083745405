import React, { useContext, useState , useEffect} from "react";
import Link from '@mui/joy/Link';
import { getContent, getGuestRsvp } from '../api';
import Gallery from './Gallery'
import './style.css';
import {LanguageContext} from '../LanguageSelector';
import { getTemplate } from "./template";
import TopMenu from "../TopMenu";
import { Parallax, ParallaxBanner, ParallaxBannerLayer, useParallax } from 'react-scroll-parallax';
import { parseJwt } from '../parseJwt';

const cardStyles = {
  maxWidth: "400px",
  width: "70%",
  margin: "20px auto",
  padding: "40px",
  background: "#f9f8f4", // Subtle off-white paper texture
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Subtle shadow
  textAlign: "center",
  color: "#333",
  lineHeight: "1.8",
};

const imageUrlByContactImage = {
  "sms.png": `${process.env.PUBLIC_URL}/sms.png`,
  "kakaotalk.png": `${process.env.PUBLIC_URL}/kakaotalk.png`,
  "whatsapp.png": `${process.env.PUBLIC_URL}/whatsapp.png`,
}

const HandWritingFont = ({ children, style }) => {
  const fontStyles = {
    fontFamily: '"Grape Nuts", cursive',
    fontSize: `${1.2 + Math.random()/2}em`,
    lineHeight: "0.8em",
    height: "0.8em",
    color: "gray", // Gray color like pencil
    borderBottom: "1px solid #333",
    textAlign: "start",
    padding: `0 0 0 ${1 + Math.random()}em`,
    ...style,
  };

  return <div style={fontStyles}>{children}</div>;
};

const headerStyles = {
  fontSize: "2.5em",
  fontWeight: "bold",
  letterSpacing: "2px",
  textTransform: "uppercase",
};

const subheaderStyles = {
  fontSize: "1.5em",
  fontWeight: "bold",
  letterSpacing: "2px",
  textTransform: "uppercase",
};
const WeddingCard = ({template, contentByLanguage, guestName, rsvpData}) => {
  return (
    <div style={cardStyles}>
      <p style={subheaderStyles}>Hachland Hill<br/>5396 Rawlings Rd,<br/>Joelton, TN 37080</p>
      <p style={headerStyles}>4:30pm<br/>June 22, 2025</p>
      {/* <p style={subheaderStyles}>{Math.ceil((new Date("June 22, 2025").getTime() - Date.now())/(1000 * 60 * 60 * 24))} Days To Go!</p> */}
      <div style={{paddingRight: "3em", justifyItems: "start", width: "100%"}}>
        <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "0.4em"}}> 
          <div>RSVP by:</div>
          <HandWritingFont style={{marginLeft: "1em", flexGrow: 1}}>{guestName}</HandWritingFont>
        </div>
        <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "0.4em"}}> 
          <div>Coming?</div>
          <HandWritingFont style={{marginLeft: "1em", flexGrow: 1}}>{rsvpData?.isComing? template.yes: template.no}</HandWritingFont>
        </div>
        <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "0.4em"}}> 
          <div>Meal Prefrennce:</div>
          <HandWritingFont style={{marginLeft: "1em", flexGrow: 1}}>{template[rsvpData?.foodStatus]}</HandWritingFont>
        </div>
        <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "0.4em"}}> 
          <div>Total Guests:</div>
          <HandWritingFont style={{marginLeft: "1em", flexGrow: 1}}>{rsvpData?.guestCount}</HandWritingFont>
        </div>
        <div style={{display: "flex", flexDirection: "column", width: "100%", marginTop: "2em"}}> 
          <div style={{textAlign: "center"}}>
                    <div>{template?.contact}</div>
                    <Link href={`mailto:${template?.email}`}>{template?.email}</Link>
                    <div>{template?.groomsConatctName}: {contentByLanguage?.groomsPhones?.map((x, i) => <Link key={`groom-number-${i}`} startDecorator={<img style={{height: "1em"}} src={imageUrlByContactImage[x.img]} alt={x.img} />} style={{ margin: "0 0.5em 0 0.5em"}} target='_blank' dir='ltr' href={x.link}>{x.value}</Link>)}</div>
                    <div>{template?.bridesContactName}: {contentByLanguage?.bridesPhones?.map((x, i) => <Link key={`bride-number-${i}`} startDecorator={<img style={{height: "1em"}} src={imageUrlByContactImage[x.img]} alt={x.img} />} style={{ margin: "0 0.5em 0 0.5em"}} target='_blank' dir='ltr' href={x.link}>{x.value}</Link>)}</div>
                </div>
        </div>
      </div>
    </div>
  );
};

const fontByLanguage = {
  en: 'Moderustic',
  he: 'VarelaRound-Regular',
  ko: 'Cafe24Oneprettynight'
}

function Main({ onLogout, token}) {
  const {language, dir} = useContext(LanguageContext);
  const [rsvpData, setRsvpData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [errorState, setErrorState] = useState(false);


  useEffect(() => {
      Promise.all([getContent(token), getGuestRsvp(token)]).then(([content, rsvp]) => {
          setRsvpData(rsvp);
          setContent(content);
          setIsLoading(false);
      }).catch((err) => {
          if(err.response.status === 401) {
              onLogout();
          }
          setErrorState(true);
      });
  }, [token, onLogout]);
  const font = fontByLanguage[language];

  const contentByLanguage = content?.[language];
  const template = content? getTemplate(content)[language]: {};
  const parsedToken = parseJwt(token);
  const guestName = parsedToken.name ?? template.backupName;

  const parallax = useParallax({
    onProgressChange: (progress) => {
      if (parallax.ref.current) {
        // set progress to CSS variable
        parallax.ref.current.style.setProperty(
          "--progress",
          progress.toString()
        );
      }
    },
  });
  return (
    <>
      <TopMenu onLogout={() => onLogout()}/>
      <div style={{overflowX: 'hidden', display: 'unset', width: "100%", fontFamily: font}}>
      <div style={{width: "100%", height: "100%"}}>
      <ParallaxBanner width="100px" height="100px" style={{width: "100%", height: "100%"}}>
        <ParallaxBannerLayer image={process.env.PUBLIC_URL + "/photos/engagement_session_1.png"} speed={-10} />
        <ParallaxBannerLayer style={{width: "100%", height: "100%", display: "flex", justifyContent: "space-around", alignItems: "center"}} speed={5} scale={[1, 1.05, 'easeOutCubic']}>
          <h1 style={{color: "white", fontFamily: "AnandaBlackPersonalUseRegular", fontSize: "3em", lineHeight: "1"}}>{template.groomsConatctName} & {template.bridesContactName}</h1>
        </ParallaxBannerLayer>
      </ParallaxBanner>
      </div>  
      
      <Parallax translateY={[-60, 25]}>
        <WeddingCard template={template} contentByLanguage={contentByLanguage} guestName={guestName} rsvpData={rsvpData}/>
      </Parallax>
      <Parallax translateY={[-10, 0]}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <img style={{width: "100%",zIndex: 400}} src={process.env.PUBLIC_URL + "/photos/engagement_session_no_portrait.png"} />
          <div style={{
                display: "flex", 
                flexDirection: "column", 
                width: "100%", 
                alignItems: "center", 
                justifyContent: "center", 
                backgroundColor: "#00674f",
                color: "white",
                paddingBottom: "15em"
              }}>
                <h1 style={headerStyles}>Our Story</h1>
                <Parallax style={{zIndex: 0, maxWidth: "300px", maxWidth: "30em", textAlign: "start", width: "80%", fontSize: "1.5em",}} translateX={[2, -2]}>
                  <p>We both moved to Nashville, Tennessee from abroad—Hansol from South Korea and Bar from Israel—without knowing what awaits for us in that distant land. But aperentaly the world had a special plan for us here — to meet our special someone.</p>
                  <p>Our first meeting took place on May 6th, 2023, at the Bicentennial Park, a beautiful and historic spot in Nashville. We picked up coffee and chocolate from Tempered Chocolate and shared our stories, discovering that we had a lot in common. We imidiately felt like friends waiting for our next meeting, and we fell in love quickly.</p>
                </Parallax>
                {/* </Parallax>    */}
                <Parallax style={{zIndex: 0, maxWidth: "300px", maxWidth: "30em", textAlign: "start", width: "80%", fontSize: "1.5em",}} translateX={[2, -2]}>
                  <p>Since then, we've explored so many amazing places together, both across the U.S., the Caribbean islands, and even in South Korea, sharing a mutual love for travel and exploration!</p>
                  <p>In May of 2024, we visited Chicago with Bar’s parents and decided to celebrate our first date with a special day of our own. We went to the Chicago Botanic Garden, and while strolling through the peaceful paths, Bar popped out the question, and Hansol happily accepted.</p>
                </Parallax>
          </div>
          <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
            <Parallax translateY={[0, -100]} translateX={[0, 20]}>
                <HandWritingFont style={{
                  maxWidth: "30em",
                  width: "50%",
                  color: "white",
                  borderBottom: "unset",
                  margin: "20px 3em",
                  fontSize: "2em",
                  zIndex: 100,
                }}>Our First Date <br/>Happend Here ↓<br/></HandWritingFont>
                <img src={process.env.PUBLIC_URL + "/photos/5.jpeg"}   style={{
                  maxWidth: "30em",
                  width: "70%",
                  border: "10px solid white", // White border
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Subtle shadow
                  display: "block", // Centers the image in its container
                  margin: "50px 3em 20px 3em" // Adds spacing and centers horizontally
                }}/>
            </Parallax>
          </div>
        </div>
      </Parallax>
      <Parallax translateY={[-10, 0]}>
      <section id="q-and-a" style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{width: '100%'}}>
            <div style={{display: "flex", justifyContent: "center"}}>
              <h1 
                ref={parallax.ref}
                style={{
                  fontSize: "calc(25vw + 10vw * var(--progress))", 
                  textAlign: "center", 
                  width: "100%", 
                  marginTop: '10vh', 
                }}>
                  Q & A
              </h1>
            </div>
            <Parallax translateX={[-10, 10]} rotate={[0, 2]}>
            <div style={cardStyles}>
              <h1>What is the dress code?</h1>
              <p>
                The dress code is Garden party casual. Please avoid these items below for your convinence. Stilettos - the venue will feature unpaved roads and grass. Full suit - it'll be hot and humid, no need for a sauna session during the wedding
              </p>
            </div>
          </Parallax>
          </div>
          <div style={{width: '100%'}}>
            <Parallax translateX={[10, -10]} rotate={[0, -2]}>
            <div style={cardStyles}>
              <h1>Where should I stay?</h1>
              <p>
              We recommend staying in Downtown Nashville or Germantown Nashville, as that is where the wedding party, and optional activities will be. We have a room block at The Hachland hill. Space for the room block is limited, but there are many other hotels with varying price ranges in the Nashville area. If you don't want to stay at the Hachland hill, please look at the Accommodations page to check out our other recommendations!
              </p>
            </div>
          </Parallax>
          </div>
          <div style={{width: '100%'}}>
            <Parallax translateX={[-10, 10]} rotate={[0, 2]}>
            <div style={cardStyles}>
            <h1>Is parking available at the wedding venue?</h1>
            <p>The hachland hill does offer ( ) parking on site if you choose to drive there on your own. If you plan to drive, let us know so we can ensure we are not at parking capacity.</p>
            </div>
          </Parallax>
          </div>
          <div style={{width: '100%'}}>
            <Parallax translateX={[10, -10]} rotate={[0, -2]}>
            <div style={cardStyles}>
            <h1>Is the wedding indoors or outdoors?</h1>
            <p>The wedding will be completely outdoors. There may be intermittent rain but we will provide umbrella on site.</p>
            </div>
          </Parallax>
          </div>
          <div style={{width: '100%'}}>
            <Parallax translateX={[-10, 10]} rotate={[0, 2]}>
            <div style={cardStyles}>
              <h1>Do we need to attend all of the wedding events (besides the ceremony)?</h1>
              <p>No, of course not! All extra events are optional. There's an optional event : Nashville Honkytonk (RSVP required)</p>
            </div>
            </Parallax>
          </div>
          <div style={{width: '100%'}}>
            <Parallax translateX={[10, -10]} rotate={[0, -2]}>
            <div style={cardStyles}>
              <h1>What will be the weather be like?</h1>
              <p>Average June temperatures in Nashville, TN (where the wedding venue is) is a high of 86 °F and a low of 67 °F. In Nashville in June, there's a 36% chance of rain on an average day and it would be somewhat light rain. Therefore, be aware that your attire might get wet!</p>
            </div>
          </Parallax>
          </div>
          <div style={{width: '100%'}}>
            <Parallax translateX={[-10, 10]} rotate={[0, 2]}>
            <div style={cardStyles}>
            <h1>First time in Nashville?</h1>
            <p>If this is your first time in Nashville, we recommend you check out this website for things you can do to get a good introduction to this Music city!</p>
            </div>
          </Parallax>
          </div>
      </section>        
      </Parallax>
      <ParallaxBanner width="100px" height="100px" style={{width: "100%", height: "100%"}}>
              <ParallaxBannerLayer image={process.env.PUBLIC_URL + "/vanue.jpg"} speed={1} />
              <ParallaxBannerLayer image={process.env.PUBLIC_URL + "/vanue.jpg"} speed={-10} />
              <ParallaxBannerLayer style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center"}} speed={5} scale={[1, 1.05, 'easeOutCubic']}>
                <h1 style={{color: "white", fontFamily: "AnandaBlackPersonalUseRegular", fontSize: "3em", lineHeight: "1"}}>Accommodations</h1>
              </ParallaxBannerLayer>
      </ParallaxBanner>

      <div style={{width: '100%', marginBottom: "10vh"}}>
          <Parallax translateX={[-5, 5]} translateY={[-70,-110]} rotate={[5, -10]}>
            <div style={cardStyles}>
              <h1>Hachland Hill</h1>
              <p>Check-In: Saturday, June 21, 2025<br/>Check-Out: Monday, June 23, 2025</p>
              <p>This historic inn & venue is located in Joelton, TN which takes about 20 - 30 minutes from Nashville</p>
              <p>The room rate would be $ 210 for 2 nights, or $ 310 for 1 night 
                <br/><strong>including breakfast.</strong></p>
              <a>Contact us for more</a>
            </div>
        </Parallax>
      </div>
      <Parallax translateY={[-15,0]}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}>
          <Parallax translateX={[10, -10]}>
            <h1 style={{fontSize: "25vw", textAlign: "center", width: "100%", margin: 0}}>THINGS</h1>
          </Parallax>
          <Parallax translateX={[-10, 10]}>
          <h1 style={{fontSize: "70vw", textAlign: "center", width: "100%", margin: 0}}>TO</h1>
          </Parallax>
          <Parallax translateX={[10, -10]}>
          <h1 style={{fontSize: "65vw", textAlign: "center", width: "100%", margin: 0}}>DO</h1>
          </Parallax>
          <Parallax translateX={[-10, 10]}>
          <h1 style={{fontSize: "75vw", textAlign: "center", width: "100%", margin: 0}}>IN</h1>
          </Parallax>
          <Parallax translateX={[10, -10]}>
          <h1 style={{fontSize: "35vw", textAlign: "center", width: "100%", margin: 0}}>NASH</h1>
          </Parallax>
          <Parallax translateX={[-10, 10]}>
          <h1 style={{fontSize: "35vw", textAlign: "center", width: "100%", margin: 0}}>VILLE</h1>
          </Parallax>
          <p style={{fontSize: "7vw", textAlign: "center", width: "90%", margin: "5vh", marginBottom: "20vh"}}>
            Our wedding location is Nashville, TN which is known as Music city. We've lived in for about 2 years and we have had lots of fun in this amazing city. There are some recommendations we'd like to do and there's shared goole map places for the visitors.
          </p>
            <div style={{width: "70%", maxWidth: "30em"}}>
            <Parallax translateY={[0,10]} rotate={[-150, 150]}>
                <Link href="https://www.google.com/maps/d/u/0/embed?mid=18macp7EGXz7dzh3bir0ERCw9oyc21Q0&ehbc=2E312FF&noprof=1" target="_blank">
                  <img src={process.env.PUBLIC_URL + "/mapsIcon.png"} width="100%"/>
                </Link>
            </Parallax>
            </div>

      </div>
      </Parallax>
      <Parallax translateY={[0, 0]}>
        <Parallax translateY={[50, -10]}>
          <h1 style={{
            fontSize: "25vw", 
            textAlign: "center", 
            width: "100%", 
            height: "50vh",
            background: "linear-gradient(180deg, #00674f 40%, #ffffff 100%)", 
            color: 'white', 
            padding: '1vw',
            paddingTop: "10vh",
            }}>Gallery</h1>
        </Parallax>
        <Gallery />
      </Parallax>
      <div>
        <Parallax translateY={[150, -100]}>
          <p style={{fontSize: "10vw", textAlign: "center", width: "100%", margin: 0}}>
              See Y'all Soon!
          </p>
        </Parallax>
        <img src={process.env.PUBLIC_URL + "/engagment_cutout.png"}
          style={{
            width: "100%",
            display: "block",
          }}/>
      </div>
      </div>
    </>
  );
}

export default Main;